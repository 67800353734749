import React from 'react'
import './footer.styles.css'


export default function Footer() {
    return (
        <div className="footer-wrapper">
            <span>© 2022 aswaff.dev. All rights reserved.</span>
        </div>
    );

};
